import { Routes } from '@angular/router';
import { CONSTANTS } from '@cue/admin-constants';
import { AccountExistsGuard, LicenseExistGuard, UserRoleGuard } from '../account/guards';
import { UserIsLoggedInGuard } from '../core/guards';
import { LayoutComponent } from './components';

export const layoutRoutes: Routes = [
  {
    path: '',
    canActivate: [AccountExistsGuard, UserIsLoggedInGuard, LicenseExistGuard, UserRoleGuard],
    canActivateChild: [UserRoleGuard],
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: CONSTANTS.paths.dashboard,
        pathMatch: 'full',
      },
      {
        path: CONSTANTS.paths.notFound,
        loadChildren: () => import('@cue/admin-not-found').then((x) => x.notFoundRoutes),
        data: { roles: [CONSTANTS.code.adminRole, CONSTANTS.code.analyticsRole] },
      },
      {
        path: CONSTANTS.paths.analyticsTemplate,
        loadChildren: () => import('@cue/admin-analytics-template').then((x) => x.analyticsTemplateRoutes),
        data: { roles: [CONSTANTS.code.adminRole, CONSTANTS.code.analyticsRole] },
      },
      {
        path: CONSTANTS.paths.areas,
        loadChildren: () => import('@cue/admin-areas').then((x) => x.areasRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.connectionAgents,
        loadChildren: () => import('@cue/admin-connection-agent').then((x) => x.connectionAgentRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.languages,
        loadChildren: () => import('@cue/admin-languages').then((x) => x.languagesRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.log,
        loadChildren: () => import('@cue/admin-log').then((x) => x.logRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.security,
        loadChildren: () => import('@cue/admin-security').then((x) => x.securityRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.properties,
        loadChildren: () => import('@cue/admin-properties').then((x) => x.propertiesRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.resources,
        loadChildren: () => import('@cue/admin-resources').then((x) => x.resourcesRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.resourceProperties,
        loadChildren: () => import('@cue/admin-resource-properties').then((x) => x.resourcePropertiesRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.sensor,
        loadChildren: () => import('@cue/admin-sensor').then((x) => x.sensorRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.serverExchangeAndAD,
        loadChildren: () => import('@cue/admin-server-exchange-and-ad').then((x) => x.serverExchangeAndAdRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.serverOffice365,
        loadChildren: () => import('@cue/admin-server-office-365').then((x) => x.serverOffice365Routes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.serviceAccounts,
        loadChildren: () => import('@cue/admin-service-account').then((x) => x.serviceAccountRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.support,
        loadChildren: () => import('@cue/admin-support').then((x) => x.supportRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.setup,
        loadChildren: () => import('@cue/admin-setup').then((x) => x.setupRoutes),
        data: { roles: [CONSTANTS.code.adminRole, CONSTANTS.code.analyticsRole] },
      },
      {
        path: CONSTANTS.paths.timetables,
        loadChildren: () => import('@cue/admin-timetables').then((x) => x.timetablesRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.timeSettings,
        loadChildren: () => import('@cue/admin-time-settings').then((x) => x.timeSettingsRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.units,
        loadChildren: () => import('@cue/admin-units').then((x) => x.unitsRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.users,
        loadChildren: () => import('@cue/admin-users').then((x) => x.usersRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.about,
        loadChildren: () => import('@cue/admin-about').then((x) => x.aboutRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.touchONEAssist,
        loadChildren: () => import('@cue/admin-touch-one-assist').then((x) => x.touchONEAssistRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.licenses,
        loadChildren: () => import('@cue/admin-licenses').then((x) => x.licensesRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.resourceTypes,
        loadChildren: () => import('@cue/admin-resource-types').then((x) => x.resourceTypesRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.adminUsers,
        loadChildren: () => import('@cue/admin-admin-users').then((x) => x.adminUsersRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.dashboard,
        loadChildren: () => import('@cue/admin-dashboard').then((x) => x.dashboardRoutes),
        data: { roles: [CONSTANTS.code.adminRole, CONSTANTS.code.analyticsRole] },
      },
      {
        path: CONSTANTS.paths.news,
        loadChildren: () => import('@cue/admin-news').then((x) => x.newsRoutes),
        data: { roles: [CONSTANTS.code.adminRole, CONSTANTS.code.analyticsRole] },
      },
      {
        path: CONSTANTS.paths.notifications,
        loadChildren: () => import('@cue/admin-notifications').then((x) => x.notificationsRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.integrations,
        loadChildren: () => import('@cue/admin-integrations').then((x) => x.integrationsRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.qrCodes,
        loadChildren: () => import('@cue/admin-qr-codes').then((x) => x.qrCodesRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.qrCategories,
        loadChildren: () => import('@cue/admin-qr-categories').then((x) => x.qrCategoriesRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.navigation,
        loadChildren: () => import('@cue/admin-navigation').then((x) => x.navigationRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.accessDenied,
        loadChildren: () => import('@cue/admin-access-denied').then((x) => x.accessDeniedRoutes),
        data: { roles: [CONSTANTS.code.adminRole, CONSTANTS.code.analyticsRole] },
      },
      {
        path: CONSTANTS.paths.userGroups,
        loadChildren: () => import('@cue/admin-user-groups').then((x) => x.groupsRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.debug,
        loadChildren: () => import('@cue/admin-debug').then((x) => x.debugRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.calendar.main,
        loadChildren: () => import('@cue/admin-calendar').then((x) => x.calendarRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.newAnalytics,
        loadChildren: () => import('@cue/admin-new-analytics').then((x) => x.newAnalyticsRoutes),
        data: { roles: [CONSTANTS.code.adminRole, CONSTANTS.code.analyticsRole] },
      },
      {
        path: CONSTANTS.paths.imageTemplates,
        loadChildren: () => import('@cue/admin-image-templates').then((x) => x.imageTemplatesRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.kiosks,
        loadChildren: () => import('@cue/admin-kiosks').then((x) => x.kiosksRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.cookies,
        loadChildren: () => import('@cue/admin-cookies').then((x) => x.cookiesRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.design,
        loadChildren: () => import('@cue/admin-design').then((x) => x.DesignRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.digitalSignage,
        loadChildren: () => import('@cue/admin-digital-signage').then((x) => x.digitalSignageRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.paths,
        loadChildren: () => import('@cue/admin-paths').then((x) => x.pathsRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
      {
        path: CONSTANTS.paths.projects,
        loadChildren: () => import('@cue/admin-projects').then((x) => x.projectsRoutes),
        data: { roles: [CONSTANTS.code.adminRole] },
      },
    ],
  },
];
